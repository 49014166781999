import React from 'react'

const EmailPicker = ({ email }) => {
  return (
    <form action="#" method="POST" className="mt-3 sm:flex">
      <label htmlFor="email" className="sr-only">
        {email.lable}
      </label>
      <input
        type="email"
        name="email"
        id="email"
        className="block w-full p-3 text-base rounded-md placeholder-gray-500 border shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:flex-1 border-gray-300"
        placeholder={email.placeholder}
      />
      <button
        type="submit"
        className="mt-3 w-full px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-primary-600 shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:flex-shrink-0 sm:inline-flex sm:items-center sm:w-auto"
      >
        {email.button}
      </button>
    </form>
  )
}

export default EmailPicker
