import React from 'react'
import { graphql } from 'gatsby'
import AcademyLayout from 'container/AcademyLayout'
import ArticleCard from 'components/ArticleCard'
import FrontArticle from 'components/FrontArticle'
import ThumbDown from 'Assets/icons/ThumbDown.svg'
import ThumbUp from 'Assets/icons/ThumbUp.svg'
import BlogLayout from 'container/BlogLayout'
import BlogSidebar from 'container/BlogLayout/BlogSidebar'
import {slugToText} from '../../../../utils/util'
import FacebookCircleIcon from 'Assets/icons/FacebookCircleIcon.svg'
import TwitterCircleIcon from 'Assets/icons/TwitterCircleIcon.svg'
import LinkedInCircleIcon from 'Assets/icons/LinkedInCircleIcon.svg'

export default function Article({data}) {
  const { markdownRemark, sectionArticles } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  const {type} = frontmatter
  const impLinks = []
  
  sectionArticles && sectionArticles.nodes.map(item=>{
    if(item.frontmatter.category === frontmatter.category && item.frontmatter.link !== 'index' && item.frontmatter.link !== frontmatter.link)
      impLinks.push(<a href = {`/academy/${item.frontmatter.category}/${item.frontmatter.link}`}><div className='max-w-sm h-32 border rounded-md shadow-lg pt-4 pl-6 pr-2'>
      <h4 className='font-bold pb-1'> {item.frontmatter.title} </h4>
      <div className='text-sm'>{item.frontmatter.description}</div>
    </div></a>)
  })

  return <div>
    {type === 'academy' ? <AcademyLayout category={frontmatter.category} link={frontmatter.link} articles={true}>
      <div class='max-w-7xl mx-auto'>
        <FrontArticle frontmatter={frontmatter}/>
        <div className='mt-6 mb-10' dangerouslySetInnerHTML={{__html: html}}></div>
        <div className='mt-6 mb-10'>
          <div>
            {impLinks.length > 0 ? <div>
            <h3 className='font-bold text-lg mb-4'>Important Links</h3>
            <div className='grid grid-cols-2 gap-6'>
              {impLinks}
            </div></div> : null}
          </div>
        </div>
        <div className='mt-6 mb-10 font-bold text-lg flex items-center'>
          Was this page helpful? 
          <span>
            <button href='#' class='ml-8 bg-blue-700 hover:bg-grey text-grey-darkest font-bold py-2 px-4 rounded inline-flex items-center h-10'> 
              <img src={ThumbUp} alt='ThumbUp' class='h-4 mr-1'/> <span class='text-white text-sm'>Yes</span>
            </button>
          </span>
          <span>
            <button href='#' class='ml-4 bg-blue-700 hover:bg-grey text-grey-darkest font-bold py-2 px-4 rounded inline-flex items-center h-10'>
              <img src={ThumbDown} alt='ThumbDown' class='h-4 mr-1'/> <span class='text-white text-sm'>No</span>
            </button> 
          </span>
        </div>
      </div>
    </AcademyLayout>
    : <BlogLayout activeTab={frontmatter.link}>
      <div>
        <div className='max-w-7xl mx-auto md:justify-start md:space-x-10'>
          <div className='flex'>
            <div className='grow mt-6 mb-10 px-8'>
              <div className='mb-4'>
                  <div className='capitalize text-blue-500 text-xs mb-2 font-bold'><a href={`/blog/tags/${frontmatter.tags[0]}`}>{slugToText(frontmatter.tags[0])}</a></div>
                  <div className='text-xl font-bold mb-2'> {frontmatter.title} </div>
                  <div className='text-sm mb-4'>{frontmatter.description}</div>
                  {/* <div>Image</div> */}
                  <div>
                    <img className='object-cover aspect-[18/7] w-full' src={frontmatter.heroImage}/>
                  </div>
              </div>
              <div className='pb-8 border-b border-black' dangerouslySetInnerHTML={{__html: html}}></div>
              <div className='pt-6 text-sm font-bold flex space-x-4 items-center'> 
                  <p>Loved the read? Share:</p>
                  <img src={FacebookCircleIcon}/>
                  <img src={TwitterCircleIcon}/>
                  <img src={LinkedInCircleIcon}/>
              </div>
            </div>
            <div className='w-80 ml-2 mt-28 flex-none'>
              <BlogSidebar tags={frontmatter.tags}/>
            </div>
          </div>
        </div>
        <div className='bg-gray-100 py-10'>
          <h1 className='text-3xl font-bold text-center'>More blogs for you</h1>
          <div className='max-w-7xl px-8 mx-auto grid grid-cols-1 md:grid-cols-2 justify-items-start py-10 gap-x-16 gap-y-20'>
            {sectionArticles.nodes.map(node => {
              if(node.frontmatter.type===type)
              return (
                <div>
                  <ArticleCard frontmatter={node.frontmatter} blog={true}/>
                </div>
              )
            })}
          </div>
        </div>
      </div>
  </BlogLayout>}
  </div>
}
export const pageQuery = graphql`
   query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        category
        link
        title
        heroImage
        type
        description
        tags
      }
      headings {
        id
        value
      }
    }
    sectionArticles : allMarkdownRemark {
      nodes {
        frontmatter {
          title
          description
          category
          link
          type
          tags
          heroImage
        }
      }
    }
  }
`
