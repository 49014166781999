import React from 'react'
import {navigate} from 'gatsby'

export default function FrontArticle(props) {
    return <div onClick={() => {
          const link = props.frontmatter.type==='academy' ? `/academy/${props.frontmatter.category}` : `/blog/${props.frontmatter.link}`;
          return navigate(link)}} 
          className='cursor-pointer h-full grid grid-cols-1 md:grid-cols-2 justify-items-start pl-2 pb-8 gap-x-16 items-center border-b border-grey-500'>
    <div className='order-last md:order-first h-72 flex items-center'>
      <div>
        <div className='text-gray-900 font-bold text-lg hover:underline'>{props.frontmatter.title}</div>
        <p className='text-gray-700 text-base text-sm pt-2'>{props.frontmatter.description}</p>
        <div className='font-bold text-blue-500 text-md pt-4'>Learn more {'>'}</div>
      </div>
    </div>
    <div>
      <img className='object-contain rounded-tl-lg rounded-bl-lg aspect-[14/9] max-h-72' src={props.frontmatter.heroImage} alt="Article image"/>
    </div>
  </div>
}
