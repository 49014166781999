import React from 'react'

export default function AcademyNavbar(props) {

  const {navList, active} = props
  
  return <nav className="bg-white shadow-md">
  <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 md:justify-start md:space-x-10">
      <div className="flex justify-between">
          <div className="flex space-x-7">
              {/* <!-- Primary Navbar items --> */}
              <div className="hidden md:flex items-center space-x-2">
                  {navList.map((item, index) => {
                      if(item.category === active)
                        return <a href={`/academy/${item.category}`} className="py-4 px-4 text-blue-500 border-b-4 border-blue-500 font-semibold text-sm">{item.title}</a>
                      return <a href={`/academy/${item.category}`} className="py-4 px-4 text-gray-500 font-semibold hover:text-blue-500 transition duration-300 text-sm">{item.title}</a>
                  })}
              </div>
          </div>
      </div>
  </div>
  {/* <!-- mobile menu --> */}
    <div class="md:hidden mobile-menu ">
        <ul class="">
            {navList.map((item, index) => {
                  if(item.category === active)
                    return <li><a href={`/academy/${item.category}`} className="block text-sm px-2 py-4 text-white bg-blue-500 font-semibold">{item.title}</a></li>
                  return <li><a href={`/academy/${item.category}`} className="block text-sm px-2 py-4 hover:bg-blue-500 transition duration-300">{item.title}</a></li>
              })}
        </ul>
    </div>
  </nav>
}
