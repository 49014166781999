import React from 'react'
import {graphql, useStaticQuery} from 'gatsby'
import ChevronRight from 'Assets/icons/ChevronRight.svg'
import ChevronDown from 'Assets/icons/ChevronDown.svg'

export default function AcademySidebar(props) {

  const contents = []
  const data = useStaticQuery(graphql`
    query AcademyArticleDetails {
      allMarkdownRemark(filter: {fileAbsolutePath: {regex: "\/academy/"}}) {
        nodes {
          headings {
            value
            id
          }
          frontmatter {
            category
            title
            link
          }
        }
      }
    }  
  `)
  data.allMarkdownRemark.nodes.forEach((node) => {
    if(node.frontmatter.category === props.category) 
      contents.push(node)
  })
  
  return <div>
      <nav>
        <div class='flex flex-col space-y-1 text-sm'>
            {contents.map((node=>{
              console.log(node)
              if(node.frontmatter.link === 'index'){
                return <a href={`/academy/${node.frontmatter.category}`} 
                  className={`font-bold pl-4 pt-5 ${node.frontmatter.link === props.link ? 'text-blue-500' : 'hover:text-blue-500'} flex justify-between`}>
                  <div>Overview</div>
                    { node.frontmatter.link === props.link ? null : <img src={ChevronRight} alt='ChevronRight' class='h-4 mx-2'/>}
                  </a>
              }
              else{
                return <div>
                        <a href={`/academy/${node.frontmatter.category}/${node.frontmatter.link}`} 
                        className={`font-bold pl-4 pt-5 ${node.frontmatter.link === props.link ? 'text-blue-500' : 'hover:text-blue-500'} flex justify-between`}>
                          <div>{node.frontmatter.title}</div>
                          { node.frontmatter.link === props.link ? <img src={ChevronDown} alt='ChevronDown' class='h-4 mx-2'/> 
                          : <img src={ChevronRight} alt='ChevronRight' class='h-4 mx-2'/>}
                        </a>
                        <div> 
                          {node.frontmatter.link === props.link ?
                            node.headings.map((heading)=>{
                              return <a href = {`/academy/${node.frontmatter.category}/${node.frontmatter.link}/#${heading.id}`} 
                                className='font-bold pl-8 pt-5 flex justify-between hover:text-blue-500'> 
                                {heading.value} 
                              </a>
                        }) : null
                      }
                    </div>
                  </div>
              }
            }))}
        </div>
    </nav>
  </div>;
}
