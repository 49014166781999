import React from 'react'
import { navigate } from 'gatsby'
import { slugToText, truncate } from '../../utils/util'

export default function ArticleCard(props) {

    return <div className={`${props.blog ? 'max-w-xl' : 'max-w-md'}`}>
        <div class='cursor-pointer' onClick={() => {
            const link = props.frontmatter.type==='academy' ? `/academy/${props.frontmatter.category}` : `/blog/${props.frontmatter.link}`;
            return navigate(link)}}>
            <div className='relative'>
                <img className={`${props.blog ? 'aspect-[560/320]' : 'rounded-tl-lg rounded-bl-lg aspect-[14/9]'} `} src={props.frontmatter.heroImage} alt='Article image'/>          
                <div className='grid grid-flow-col auto-cols-max absolute bottom-0 left-0 ml-2 mb-1'>
                    {props.frontmatter.tags && props.frontmatter.tags.map((tag, i) => (
                        i == 0 && <div className='bg-white px-4 py-1 rounded-sm text-center hover:drop-shadow-md'>
                        <a href={`/blog/tags/${tag}`} className='text-[11px] font-medium uppercase tracking-widest'>{slugToText(tag)}</a>
                    </div>))}
                </div>
            </div>
            <div className='pt-2'>
                <div className={`${props.blog ? 'mt-4 mb-4' : ''} font-bold text-lg hover:underline`}>{props.frontmatter.title}</div>
                <p className='text-gray-700 text-base text-sm pt-2'>
                    {truncate(props.frontmatter.description)} {props.blog ? <span className='text-blue-500 font-bold'>Read more</span> : null}
                </p>
                {!props.blog ? <div className='font-bold text-blue-500 text-md pt-4'>Learn more {'>'}</div> : null}
            </div>
        </div>
    </div>
}
