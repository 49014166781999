import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import AcademyNavbar from './AcademyNavbar/index'
import AcademySidebar from './AcademySidebar/index'
import Layout from '../Layout/index'
import footer from 'data/footer.json'
import Footer from '../../components/Footer/index'

export default function AcademyLayout(props){
  const common = require('data/common.json')

  const data = useStaticQuery(graphql`
      query AcademyFeaturedCategories{
        allMarkdownRemark(filter: {fileAbsolutePath: {regex: "\/academy/"}}) {
          nodes {
            frontmatter {
              link
              title
              category
            }
          }
        }
      }
  `);
  
  const {nodes} = data.allMarkdownRemark
  const navList = [{title: 'Home', category: ''}];
  nodes.forEach(node => {
    if(node.frontmatter.link === 'index'){
      navList.push({title: node.frontmatter.title, category: node.frontmatter.category});
    }
  })

  return (
    <Layout common={common} academy={true}>
      {props.articles ? 
      <div>
        <div class='sticky top-0 z-20'>
          <AcademyNavbar navList={navList} active={props.category}/>
        </div>
        <div class='flex max-w-7xl mx-auto sm:px-6 lg:px-8 md:justify-start md:space-x-10'>
          <div class="flex-none w-64 bg-gray-100 text-gray-800 drop-shadow-xl">
            <div class='sticky top-14'>
              <AcademySidebar link={props.link} category={props.category}/>      
            </div>
          </div>
          <div class='flex flex-1 flex-col overflow-y-auto'>
            <div class='w-full'> {props.children} </div>
          </div>
        </div>
        <div> <Footer footer={footer}/> </div>
      </div> : 
      <div>
        <AcademyNavbar navList={navList} active={props.category}/> 
        {props.children} 
        <Footer footer={footer}/>
      </div>}
      
    </Layout>
  )
}