import React from 'react'
import Layout from 'container/Layout'

export default function BlogLayout(props){
  const common = require('data/common.json')
  return (
    <Layout common={common}>
      {props.children}
    </Layout>
  )
}