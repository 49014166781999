import React, { useState } from 'react'
import ChevronUp from 'Assets/icons/ChevronUp.svg'
import ChevronDown from 'Assets/icons/ChevronDown.svg'
import {slugToText} from '../../../utils/util'
import EmailPicker from 'components/EmailPicker'
export default function BlogSidebar(props) {
    
    const [tags, openTags] = useState(true);
    const [category, openCategory] = useState(false);
    const [industry, openIndustry] = useState(false);
    const hero = require('data/hero.json')

    return (
    <div className="w-60 mx-auto">
        <h1 className="text-xl text-blue-500 font-bold mb-4">Blog</h1>
        <div className='mb-4 border-b pb-2'>
            <h2 className="text-sm font-bold flex justify-between cursor-pointer" onClick={() => openTags(!tags)}>
                <div>TYPE</div> 
                <div><img src={tags ? ChevronUp : ChevronDown} alt="Chevron" className='h-4'/> </div>
            </h2>
            <div>
                {tags ? (
                    <div className='capitalize font-semibold ml-4 text-xs my-6'>
                        {props.tags.map((tag) => (<div className='mt-6'> <a href={`/blog/tags/${tag}`}>{slugToText(tag)}</a> </div>))}
                    </div>) 
                : null}
            </div>
        </div>
        <div className='mb-4 border-b py-2'>
            <h2 className="text-sm font-bold flex justify-between cursor-pointer" onClick={() => openCategory(!category)}>
                <div>CATEGORY</div> 
                <div><img src={category ? ChevronUp : ChevronDown} alt="Chevron" className='h-4'/> </div>
            </h2>
        </div>
        <div className='mb-4 border-b py-2'>
            <h2 className="text-sm font-bold flex justify-between cursor-pointer" onClick={() => openIndustry(!industry)}>
                <div>INDUSTRY</div> 
                <div><img src={industry ? ChevronUp : ChevronDown} alt="Chevron" className='h-4'/> </div>
            </h2>
        </div>
        <div className='mb-4 border-b py-2'>
            <div className='text-sm font-bold'>Subscribe to our newsletter</div>
            <form action="#" method="POST" className="mt-3 sm:flex-col">
                <label htmlFor="email" className="sr-only">
                    {hero.email.lable}
                </label>
                <input
                    type="email"
                    name="email"
                    id="email"
                    className="block w-full p-3 text-base rounded-md placeholder-gray-500 border shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:flex-1 border-gray-300"
                    placeholder={hero.email.placeholder}
                />
                <button
                    type="submit"
                    className="w-full px-6 py-2 border border-transparent text-base font-medium rounded-md text-white bg-primary-600 shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 mt-6"
                >
                    Subscribe
                </button>
                </form>
        </div>
    </div>
  )
}
